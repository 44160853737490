$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #FCFAED;
$color-black: #050304;
$primary-color: #700;

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../font/HelveticaNeue-CondensedBold.woff2') format('woff2'),
        url('../font/HelveticaNeue-CondensedBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../font/HelveticaNeue.woff2') format('woff2'),
        url('../font/HelveticaNeue.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('../font/HelveticaNeue-CondensedBlack.woff2') format('woff2'),
        url('../font/HelveticaNeue-CondensedBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

$primary-font: "Helvetica Neue", sans-serif;