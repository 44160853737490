@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	font-size: 16px;
	width: 100%;
	overflow-x: hidden;
}

body {
	background: $color-white;
	color: $color-black;
	font-family: $primary-font;
	line-height: 1.5;
	width: 100%;
	overflow-x: hidden;
	opacity: 0;
	animation: fadein .5s forwards;
}

@keyframes fadein{
	from{
		opacity: 0;
	}
	to{
		opacity: 1;
	}
}

.container{
	max-width: rems(1350);
}

a {
	color: inherit;

	@include hover-focus {
		color: $primary-color;
	}
}

h2{
	font-size: rems(69);
	line-height: 1;
	font-weight: 900;
	margin:0;
	text-transform: uppercase;
	letter-spacing: -.05em;
	@media screen and (max-width: 1280px){
		font-size: rems(40);
	}
}
h3{
	font-size: rems(116);
	@media screen and (max-width: 1280px){
		font-size: rems(50);
	}
	line-height: 1;
	font-weight: 900;
	margin:0;
	text-transform: uppercase;
	letter-spacing: -.05em;
	span{
		display: inline-block;
		font-weight: 700;
	}
}

.btn{
	border: 2px solid $color-black;
	background-color: $color-black;
	color: $color-white;
	transition: .3s all;
	display: inline-block;
	margin: rems(30) 0 0;
	padding: .5em 2em;
	font-size: rems(22);
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 900;
	@include hover-focus{
		background-color: $color-white;
		color: $color-black;
	}
}

#header {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	right: 0;
	.container{
		padding: rems(30) rems(15);
		display: flex;
		justify-content: space-between;
		align-items: center;
		.menu-toggle{
			display: none;
			@media screen and (max-width: 800px){
				display: block;
				font-size: rems(30);
				position: absolute;
				z-index: 10;
				top: 0;
				right: rems(10);
			}
			i:last-child{
				display: none;
			}
			&.open{
				i:first-child{
					display: none;
				}
				i:last-child{
					display: block;
				}
			}
		}
		.social{
			display: inline-block;
			vertical-align: middle;
			font-size: rems(24);
			margin-left: rems(20);
		}
		h1{
			display: inline-block;
			vertical-align: middle;
			max-width: rems(200);
			width: 100%;
			margin: 0;
			
			@media screen and (max-width: 380px){
				max-width: rems(150)
			}
		}
		a{
			font-weight: bold;
			text-transform: uppercase;
			text-decoration: none;
		}
		nav{
			display: flex;
			font-size: rems(20);
			gap: rems(30);
			
			@media screen and (max-width: 800px){
				position: absolute;
				top: rems(-500);
				right: 0;
				padding: rems(60) rems(30) rems(30);
				border-left: 3px solid $color-black;
				border-bottom: 3px solid $color-black;
				background-color: $color-white;
				flex-direction: column;
				align-items: center;
				transition: .3s top;
				&.open{
					top: 0;
				}
			}
		}
	}
}

#main {
	#hero{
		.container{
			min-height: 100vh;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			padding: rems(150) rems(40);
			
			@media screen and (max-width: 600px){
				min-height: 100vw;
			}
		}

		.social{
			position: absolute;
			top: 50%;
			right: rems(10);
			transform: translateY(-50%);
			display: flex;
			flex-direction: column;
			align-items: center;
			font-size: rems(26);
		}
	} 

	.artist-box{ 
		padding: rems(160) 0; 
		.artist-wrap{
			margin-top: rems(30);
			display: flex;
			gap: rems(30);
			align-items: flex-start;
			
			>div{
				flex: 1;
				text-align: center;
				p{
					text-align: left;
					margin-top: 0;
				}
			}

			img{
				transform-origin: top right;
				transform: scale(1.3);
			}
			@media screen and (max-width: 1080px){
				flex-direction: column;
				align-items: center;
				img{
					transform: none;
					display: block;
					margin: 0 auto;
				}
			}
		}
		.social{
			font-size: rems(36);
			margin-top: rems(30);
		}
	}
	#release, .release{
		.video-wrapper{
			text-align: center;
			width: 100%;
			max-width: rems(860);
			margin: rems(100) auto;
			
			@media screen and (max-width: 1280px){
				margin: rems(50) 0;
			}
		}
	}
}

#footer {
	padding: rems(100) 0;

	.wrap{
		display: flex;
		justify-content: center;
		align-items: center;
		gap: rems(80);
		
		@media screen and (max-width: 1280px){
			flex-direction: column;
			gap: rems(50);
		}
		.col{
			padding: rems(80) 0;
			max-width: 100%;
			
			@media screen and (max-width: 1280px){
				margin: 0 rems(30);
			}
			flex: 1;
			&:first-child{
				text-align: center;
				.social{
					font-size: rems(26);
				}
			}
			&:last-child{
				position: relative;
				&::after{
					content:'';
					position: absolute;
					left: rems(-40);
					width: rems(3);
					height: 100%;
					top: 0;
					bottom: 0;
					background-color: $color-black;
					
					@media screen and (max-width: 1280px){
						width: 100%;
						height: rems(3);
						top: rems(-25);
						bottom: auto;
						left: 0;
						right: 0;
					}
				}
			}
			img{
				width: 100%;
				display: inline-block;
				max-width: rems(380);;
			}
			form{
				margin-top: rems(30);
				font-size: rems(16);
				font-weight: bold;
				text-transform: uppercase;
				.inputs-wrap{
					display: flex;
					align-items: flex-end;
					width: 100%;
					max-width: rems(400);
					gap: rems(10);
				}
				input{
					flex: 1; 
					border: none;
					background: none;
					border-top: rems(3) solid transparent;
					border-bottom: rems(3) solid $color-black;
					font-weight: bold;
					text-transform: uppercase;
					padding: .5em 0;
					color: $color-black;
					&::placeholder{
						color: $color-black;
					}
				}
				.btn{
					font-size: rems(20);
					margin: 0;
					
					@media screen and (max-width: 380px){
						padding: .5em;
					}
				}
			}
		}
	}
	.copyright{
		font-weight: bold;
		font-size: rems(12);
		text-transform: uppercase;
		text-align: center;
	}
}